<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="关键字:">
                        <el-input v-model='filters.name' placeholder="输入品牌名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
            :data="tableData"
            border
            style="width: 100%"
            v-loading="listLoading"
            @current-change='currentChange'
            :highlight-current-row='true'
        >
            <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
            <el-table-column prop="BrandName" label="品牌名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="BrandIntroduction" label="品牌简介" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="CreateTime" label="创建日期" align="center"></el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!-- 新增/编辑品牌 -->
        <el-dialog
            :title="isAddBrand ? '新增' : '编辑'"
            :visible.sync="addOrEditBrandDialog"
            v-model="addOrEditBrandDialog"
            :close-on-click-modal="false"
            width='45%'
        >
            <el-form :model='brandFrom' :rules='brandRules' ref='brandRef' label-width="120px">
                <el-form-item label="品牌名称:" prop="brandName">
                    <el-input v-model='brandFrom.brandName' placeholder="输入品牌名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="品牌简介:">
                    <el-input v-model='brandFrom.brandIntroduce' placeholder="输入品牌简介" clearable></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addOrEditBrandDialog = false">取消</el-button>
                <el-button type="primary" @click.native="addOrEditBrandSubmit" :loading="addOrEditBrandLoading">提交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import { getBrandListPage,addBrand,updateBrand,deleteBrand } from '@/api/api'
import Qs from 'qs'
export default {
    components:{Toolbar},
    data() {
        return {
            buttonList: [],
            filters:{
                name:'',
            },
            listLoading: false,
            tableData: [],
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            needData: '',
            isAddBrand: true,
            addOrEditBrandDialog: false,
            addOrEditBrandLoading: false,
            brandFrom: {
                brandName: '',
                brandIntroduce: '',
            },
            brandRules: {
                brandName:[{required:true,message:'请输入品牌名称',trigger:'blur'}],
            }
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        getDataList(){
            this.listLoading = true
            var params = {
                brandName:this.filters.name?this.filters.name:null,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            getBrandListPage(params).then(res => {
                var result = res.data
                if(result.Success){
                    this.listLoading = false;
                    this.pages.dataCount = result.Response.DataCount
                    this.pages.pageIndex = result.Response.PageIndex
                    if(result.Response.PageSize > 0){
                        this.pages.pageSize = result.Response.PageSize
                    }
                    this.tableData = result.Response.Data
                }else{
                    this.listLoading = false
                }
            })
        },
        // 查询
        getBrandListPage() {
            this.getDataList();
        },
        // 新增
        addBrand() {
            this.isAddBrand = true
            this.addOrEditBrandDialog = true
            this.addOrEditBrandLoading = false
            this.brandFrom = {
                brandName: '',
                brandIntroduce: '',
            }
        },
        // 编辑
        updateBrand() {
            if(this.needData) {
                this.isAddBrand = false
                this.addOrEditBrandDialog = true
                this.addOrEditBrandLoading = false
                this.brandFrom = {
                    brandName: this.needData.BrandName,
                    brandIntroduce: this.needData.BrandIntroduction,
                }
            } else {
                this.$message.warning("请至少选择一行编辑！")
            }
        },
        // 新增/编辑提交
        addOrEditBrandSubmit() {
            this.$refs['brandRef'].validate(valid => {
                if(valid){
                    var params = {
                        BrandName: this.brandFrom.brandName,
                        BrandIntroduction: this.brandFrom.brandIntroduce,
                    }
                    this.addOrEditBrandLoading = true
                    if(this.isAddBrand) {
                        addBrand(params).then(res => {
                            var result = res.data
                            this.addOrEditBrandLoading = false
                            if(result.Success) {
                                this.$message.success(result.Message)
                                this.addOrEditBrandDialog = false
                                this.getDataList()
                            } else {
                                this.$message.error(result.Message)
                            }
                        })
                    } else {
                        params.ID = this.needData.ID
                        updateBrand(params).then(res => {
                            var result = res.data
                            this.addOrEditBrandLoading = false
                            if(result.Success) {
                                this.$message.success(result.Message)
                                this.addOrEditBrandDialog = false
                                this.getDataList()
                            } else {
                                this.$message.error(result.Message)
                            }
                        })
                    }
                }
            })
        },
        // 删除
        handleDel() {
            if(this.needData) {
                this.$confirm(`确定将已选中删除品牌？`,"提示",{
                }).then(() => {
                    var params = {
                        ID: this.needData.ID
                    }
                    deleteBrand(params).then(res => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            this.getDataList()
                        } else {
                            this.$message.error(result.Message)
                        }
                    })
                })
            } else {
                this.$message.warning("请至少选择一行删除！")
            }
        },
        // 点击table某一行
        currentChange(val) {
            this.needData = val
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getDataList();
        },
        handleSizeChange(val){
            this.pages.pageSize = val;
            this.getDataList();
        },
        // 时间格式化
        formatCreateTime: function(row, column) {
            return !row.NoticeTime || row.NoticeTime == ""
            ? ""
            : util.formatDate.format(new Date(row.NoticeTime), "yyyy-MM-dd hh:mm");
        },
    },
    created(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        this.getDataList();
    }
}
</script>

<style lang="stylus" scoped>

</style>